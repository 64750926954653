.modalLayout {
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
    @media (max-width: 1024px) {
        visibility: hidden;
        opacity: 0;
        background-color: rgba(25, 28, 34, .4);
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 1000;
        overflow-y: scroll;
    }
    &__isOpen {
        visibility: visible;
        opacity: 1;
    }
}

.modalBody {
    padding: 16px;
    width: 500px;
    max-width: 500px;
    background-color: #f5f5f5;
    transition: transform 0.3s ease-in-out;
    @media (max-width: 1024px) {
        position: absolute;
        right: 0;
        z-index: 1050;
        transform: translateX(100%);
    }
    @media (max-width: 576px) {
        width: 80%;
        max-width: none;
    }
    &__isOpen {
        transform: translateX(0);
    }
}

.closeButton {
    position: absolute;
    top: 14px;
    left: -13px;
    padding: 11px 4px;
    z-index: 3;
    background-color: #e6e6e6;
    border-radius: 20px;
    border: 1px solid #bbbbbb;
}

.previewBlock {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    @media (max-width: 1263px) {
        width: 35%;
    }
    @media (max-width: 1024px) {
        width: 100%;
    }
}

.selectedEvents {
    padding: 16px;
    border-radius: 16px;
    background-color: #fff;
}

.selectedPost {
    margin: 8px 0;
    padding: 8px !important;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 8px;
    transition: 0.3s all;
    &:hover,
    &__isActive {
        border: 1px solid #1890ff;
        border-block-end: 1px solid #1890ff !important;
        transition: 0.3s all;
    }
    &__content {
        align-items: center !important;
    }
    &__text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
    }
    &__title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }
    &__time {
        color: rgba(0, 0, 0, 0.5);
    }
    h4 {
        margin-bottom: 0 !important;
    }
}