.layout {
  width: 100%;
  display: flex;
}

.body {
  position: relative;
  width: calc(100% - 300px);
  @media (max-width: 1024px) {
    width: 100%;
  }
  &__full {
    width: 100%;
  }
}

.menuButton {
  position: absolute;
  top: 20px;
  left: 2px;
  padding: 11px 4px;
  z-index: 3;
  background-color: #e6e6e6;
  border-radius: 20px;
  border: 1px solid #bbbbbb;
}

.main {
  padding-top: 124px;
  background-color: rgba(238, 238, 238, 1);
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 1263px) {
    padding-top: 103px;
  }
  @media (max-width: 1024px) {
    padding-top: 88px;
  }
  @media (max-width: 992) {
    padding-top: 74px;
  }
}

.footerContainer {
  display: flex;
  justify-content: space-between;
}

.socialMedias {
  display: flex;
  gap: 8px;
}

.footer {
  position: static;
}
