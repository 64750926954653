.section {
  padding: 0 40px;
  padding-top: 110px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 1263px) {
    padding: 0 30px;
    padding-top: 60px;
  }
  @media (max-width: 1024px) {
    padding: 0 24px;
    padding-top: 30px;
  }
  @media (max-width: 992px) {
    padding-top: 20px;
  }
}

.title {
  margin-bottom: 40px;
  font-size: 110px;
  line-height: 100px;
  font-weight: 600;
  text-align: center;
  color: rgba(0, 95, 170, 1);
  @media (max-width: 1263px) {
    margin-bottom: 30px;
    font-size: 92px;
    line-height: 84px;
  }
  @media (max-width: 1024px) {
    margin-bottom: 20px;
    font-size: 64px;
    line-height: 56px;
  }
  @media (max-width: 576px) {
    font-size: 40px;
    line-height: 90%;
  }
}

.subtitle {
  color: var(--black, #1b1b1b);
  font-size: 36px;
  line-height: 40px;
  font-weight: 500;
  text-align: center;
  @media (max-width: 1263px) {
    font-size: 32px;
    line-height: 32px;
  }
  @media (max-width: 1024px) {
    font-size: 26px;
  }
  @media (max-width: 576px) {
    font-size: 20px;
    line-height: 110%;
  }
}

.actions {
  margin-top: 64px;
  display: flex;
  justify-content: center;
  transition: 0.3s all;
  &__hidden {
    pointer-events: none;
    opacity: 0;
    transition: 0.3s all;
  }
  @media (max-width: 1263px) {
    margin-top: 40px;
  }
  @media (max-width: 1024px) {
    margin-top: 20px;
  }
  @media (max-width: 576px) {
    margin-top: 40px;
    justify-content: unset;
    flex-direction: column;
    gap: 10px;
  }
}

.tariffBtn {
  height: 70px;
  padding: 26px 104px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 50px;
  color: rgba(27, 27, 27, 1);
  box-shadow: 0px 14px 20px 0px rgba(0, 0, 0, 0.1);
  transform: translateX(32px);
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  @media (max-width: 1263px) {
    height: 56px;
    padding: 20px 80px;
    transform: translateX(12px);
    font-size: 16px;
  }
  @media (max-width: 1024px) {
    height: 40px;
    padding: 16px 64px;
    transform: translateX(0);
    font-size: 14px;
  }
  @media (max-width: 576px) {
    width: 100%;
    padding: 24px 0;
    height: auto;
    transform: none;
  }
  &:hover {
    color: rgba(27, 27, 27, 1) !important;
    border-color: transparent !important;
    outline: none !important;
  }
}

.startBtn {
  height: 70px;
  padding: 26px 68px;
  background-color: rgba(27, 27, 27, 1);
  border-radius: 50px;
  color: rgba(255, 255, 255, 1);
  transform: translateX(-32px);
  z-index: 2;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  @media (max-width: 1263px) {
    height: 56px;
    padding: 20px 64px;
    font-size: 16px;
  }
  @media (max-width: 1024px) {
    height: 40px;
    padding: 16px 48px;
    font-size: 14px;
  }
  @media (max-width: 576px) {
    width: 100%;
    padding: 24px 0;
    height: auto;
    transform: none;
  }
  .iconPlus {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
  }
  &:hover {
    color: rgba(27, 27, 27, 1) !important;
    border-color: unset !important;
    svg {
      path {
        fill: rgba(27, 27, 27, 1);
      }
    }
  }
}

.iconPlus {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  @media (max-width: 1024px) {
    width: 16px;
    height: 16px;
    right: 20px;
  }
}

.iconChecked {
  width: 30px;
  height: 30px;
  @media (max-width: 1024px) {
    width: 24px;
    height: 24px;
  }
}

.feature {
  margin-top: 100px;
  margin-bottom: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1263px) {
    margin-top: 64px;
    margin-bottom: 40px;
  }
  @media (max-width: 1024px) {
    margin-top: 48px;
    margin-bottom: 32px;
  }
  @media (max-width: 992px) {
    overflow-x: auto;
    white-space: nowrap;
    padding-bottom: 16px;
    margin-top: 32px;
    margin-bottom: 8px;
  }
  &__item {
    display: flex;
    align-items: center;
    gap: 16px;
    @media (max-width: 1263px) {
      gap: 12px;
    }
    @media (max-width: 1024px) {
      gap: 8px;
    }
    @media (max-width: 992px) {
      padding-left: 50px;
      display: inline-flex;
      &:first-child {
        padding-left: 0;
      }
    }
    &__text {
      color: var(--black, #1b1b1b);
      font-size: 16px;
      font-weight: 400;
      @media (max-width: 1263px) {
        font-size: 14px;
      }
      @media (max-width: 1024px) {
        font-size: 12px;
      }
    }
  }
}
