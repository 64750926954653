.companyDescr {
    padding: 24px;
    background: #fff;
    border-radius: 12px;
    &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__icons {
        display: flex;
        align-items: center;
        gap: 16px;
    }
}

.product {
    &__title {
        margin-top: 16px;
        display: flex;
        align-items: center;
        gap: 8px;
    }
}

.addButton {
    z-index: 6;
    height: 24px;
    width: 24px;
    min-width: 24px !important;
    max-width: 24px !important;
}

.addIcon {
    svg {
        height: 16px;
        width: 16px;
    }
}