.container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    min-height: 500px;
    height: calc(100vh - 124px - 391px);
    @media (max-width: 1263px) {
        height: calc(100vh - 103px - 351px);
    }
    @media (max-width: 1024px) {
        height: calc(100vh - 88px - 278px);
    }
    @media (max-width: 992) {
        height: calc(100vh - 88px - 470px);
    }
}