.infoBlock {
    display: flex;
    align-items: center;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
}

.details {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.name {
    height: auto;
    min-width: 50%;
    font-size: 20px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
    span {
        font-size: 14px;
    }
}

.actions {
    display: flex;
    align-items: center;
    gap: 8px;
}

.companyBtn {
    display: flex;
    justify-content: left;
    padding-left: 0;
    width: 100%;
    outline: none !important;
    background: transparent !important;
    border-color: transparent !important;
    text-transform: capitalize;
    &:hover,
    &:focus {
        outline: none !important;
        background: transparent !important;
        border-color: transparent !important;
    }
}

.linkAdd {
    display: flex;
    align-items: center;
}

.addButton {
    z-index: 6;
    height: 24px;
    width: 24px;
    min-width: 24px !important;
    max-width: 24px !important;
}

.addIcon {
    svg {
        height: 16px;
        width: 16px;
    }
}

.upperDivider {
    display: flex;
    align-items: center;
}

.divider {
    margin: 0 !important;
    &Text {
        padding-right: 8px;
    }
}