.postDescr {
  padding: 24px;
  background: #fff;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__icons {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 16px;
}

.mainBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.editBlock {
  width: 50%;
  max-width: 450px;
}

.picture,
.pictureBlock {
  border-radius: 16px;
  div {
    border-radius: 16px;
  }
}

.postContent,
.postHashtags,
.postLike,
.postActions {
  margin-top: 16px;
}

.postLike {
  display: flex;
  align-items: center;
  gap: 8px;
}

.postActions {
  display: flex;
  gap: 16px;
}

.pictureBlock {
  position: relative;
}

.reloadButton {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
}

.parameters {
  margin-top: 50px;
}

.radioGroup {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.textArea {
  margin: 12px 0;
}

.submitButton {
  margin-top: 12px;
}

.loader {
  text-align: center;
  display: flex;
  justify-content: center;
  font-size: 100px;
  color: rgb(22, 119, 255);
}

.imageStyleBlock {
  cursor: pointer;
  img {
    width: 150px;
    height: auto;
    border-radius: 16px;
  }
}

.details {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.name {
  height: auto;
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.redStar {
  color: red;
}
