.layout {
  font-family: "Involve";
}

.main {
  padding-top: 124px;
  background-color: rgba(238, 238, 238, 1);
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 1263px) {
    padding-top: 103px;
  }
  @media (max-width: 1024px) {
    padding-top: 88px;
  }
  @media (max-width: 992) {
    padding-top: 74px;
  }
}

.docsSection {
  padding: 60px 40px;
  &__title {
    margin-bottom: 40px;
    color: var(--blue, #005faa);
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    font-style: normal;
    text-transform: uppercase;
  }
  &__subtitle {
    margin-bottom: 20px;
    color: #444444;
    font-size: 20px;
    font-weight: 500;
  }
  &__text {
    margin-bottom: 16px;
    color: #444444;
    font-size: 16px;
    font-weight: 400;
    &__flexed {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__bold {
      margin-bottom: 16px;
      color: #444444;
      font-size: 16px;
      font-weight: 500;
    }
  }
  &__list {
    padding-left: 1em;
    &__item {
      color: #444444;
      font-size: 16px;
      font-weight: 400;
    }
  }
}

.stickyActions {
  z-index: 5;
  position: fixed;
  bottom: 40px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  pointer-events: none;
  opacity: 0;
  transition: 0.3s all;
  @media (max-width: 768px) {
    bottom: 20px;
  }
  &__visible {
    pointer-events: unset;
    opacity: 1;
    transition: 0.3s all;
  }
}

.tariffBtn {
  height: 70px;
  padding: 26px 104px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 50px;
  color: rgba(27, 27, 27, 1);
  box-shadow: 0px 14px 20px 0px rgba(0, 0, 0, 0.1);
  transform: translateX(32px);
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  @media (max-width: 1263px) {
    height: 56px;
    padding: 20px 80px;
    transform: translateX(12px);
    font-size: 16px;
  }
  @media (max-width: 1024px) {
    transform: translateX(12px);
    font-size: 14px;
  }
  @media (max-width: 576px) {
    width: 100%;
    padding: 24px 0;
    transform: translateX(24px);
  }
  &:hover {
    color: rgba(27, 27, 27, 1) !important;
    border-color: transparent !important;
    outline: none !important;
  }
}

.startBtn {
  height: 70px;
  padding: 26px 68px;
  background-color: rgba(27, 27, 27, 1);
  border-radius: 50px;
  color: rgba(255, 255, 255, 1);
  transform: translateX(-32px);
  z-index: 2;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  @media (max-width: 1263px) {
    height: 56px;
    padding: 20px 64px;
    font-size: 16px;
  }
  @media (max-width: 1024px) {
    font-size: 14px;
  }
  @media (max-width: 576px) {
    width: 100%;
    padding: 24px 0;
    transform: translateX(-24px);
  }
  .iconPlus {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
  }
  &:hover {
    color: rgba(27, 27, 27, 1) !important;
    border-color: unset !important;
    svg {
      path {
        fill: rgba(27, 27, 27, 1);
      }
    }
  }
}

.showScrollButton {
  position: fixed;
  bottom: 120px;
  right: 40px;
  z-index: 1000;
  width: 64px !important;
  height: 64px !important;
  font-size: 24px;

  @media (max-width: 1263px) {
    width: 56px !important;
    height: 56px !important;
  }

  @media (max-width: 768px) {
    bottom: 80px;
    right: 20px;
  }
}

.iconArrow {
  font-size: 32px !important;

  @media (max-width: 1263px) {
    font-size: 24px !important;
  }
}
