.postQueryDescr {
  padding: 24px;
  background: #fff;
  border-radius: 12px;
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__icons {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.product {
  &__title {
    margin-top: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.iconHeart {
  font-size: 24px;
  &__active {
    path {
      fill: #eb2f96;
    }
  }
}

.titleBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
