.section {
    padding: 40px;
    @media (max-width: 1263px) {
        padding: 30px;
    }
    @media (max-width: 1024px) {
        padding: 24px;
    }
}

.title {
    color: var(--black, #1B1B1B);
    font-size: 36px;
    font-weight: 500;
    @media (max-width: 1263px) {
        font-size: 32px;
    }
    @media (max-width: 1024px) {
        font-size: 28px;
    }
}

.accordion {
    background-color: transparent;
    border-color: transparent;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.item {
    padding: 32px;
    border-radius: 40px !important;
    background: var(--white, #FFF);
    &:hover {
        h4 {
            color: var(--blue, #005FAA);
        }
        .arrowIcon {
            path {
                fill: rgba(0, 95, 170, 1);
            }
        }
    }
    @media (max-width: 1263px) {
        padding: 24px;
    }
    @media (max-width: 1024px) {
        padding: 16px;
    }
    &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: var(--black, #1B1B1B);
        font-size: 26px;
        font-weight: 500;
        @media (max-width: 1263px) {
            font-size: 22px;
        }
        @media (max-width: 1024px) {
            font-size: 20px;
        }
        &__isActive {
            color: var(--blue, #005FAA) !important;
        }
    }
    &__text {
        margin-top: 32px;
        color: var(--black, #1B1B1B);
        font-size: 16px;
        font-weight: 400;
        line-height: 120%;
        @media (max-width: 1263px) {
            margin-top: 20px;
        }
        @media (max-width: 1024px) {
            margin-top: 12px;
        }
    }
}

.arrowIcon {
    transition: 0.3s !important;
    &__isActive {
        rotate: 180deg !important;
        transition: 0.3s !important;
        path {
            transition: 0.3s !important;
            fill: rgba(0, 95, 170, 1) !important;
        }
    }
    path {
        transition: 0.3s !important;
        fill: rgba(238, 238, 238, 1);
    }
}