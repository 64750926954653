.signUpBox {
  width: 350px;
  padding: 20px;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.signUpBox h2 {
  margin-bottom: 20px;
  color: #333333;
}

.signUpForm {
  .ant-form-item {
    margin-bottom: 16px;
  }
  .ant-input {
    border-radius: 4px;
    padding: 10px;
  }
  .signUpFormButton {
    width: 100%;
    border-radius: 20px;
    height: 40px;
    border: none;
  }
}

a {
  color: #4ea7e2;
}
