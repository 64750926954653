.section {
    padding: 80px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    @media (max-width: 1263px) {
        padding: 40px 0;
    }
    @media (max-width: 1024px) {
        padding: 20px 0;
    }
}

.container {
    width: 60%;
    text-align: center;
    @media (max-width: 1024px) {
        width: 70%;
    }
    @media (max-width: 992px) {
        width: 100%;
    }
}

.label {
    margin-bottom: 30px;
    display: inline-flex;
    padding: 10px 20px;
    border-radius: 82px;
    border: 1px solid var(--blue, #005FAA);
    color: var(--black, #1B1B1B);
    font-family: Involve;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media (max-width: 1263px) {
        margin-bottom: 20px;
        padding: 8px 16px;
        font-size: 12px;
    }
}

.title {
    margin-bottom: 30px;
    color: var(--blue, #005FAA);
    text-align: center;
    font-size: 60px;
    line-height: 110%;
    font-weight: 600;
    @media (max-width: 1263px) {
        margin-bottom: 20px;
        font-size: 48px;
        line-height: 100%;
    }
    @media (max-width: 1024px) {
        font-size: 40px;
    }
    @media (max-width: 576px) {
        font-size: 30px;
    }
}

.subtitle {
    margin-bottom: 60px;
    color: var(--black, #1B1B1B);
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    @media (max-width: 1263px) {
        margin-bottom: 30px;
        font-size: 24px;
        line-height: 100%;
    }
    @media (max-width: 1024px) {
        font-size: 20px;
    }
    @media (max-width: 992px) {
        margin: 0 auto;
        margin-bottom: 30px;
        width: 70%;
    }
    @media (max-width: 576px) {
        font-size: 18px;
    }
}

.button {
    display: inline-flex;
    padding: 28px 68px;
    border-radius: 50px;
    background: var(--black, #1B1B1B);
    border-color: var(--black, #1B1B1B);
    color: var(--white, #FFF);
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    transition: 0.3s;
    @media (max-width: 1263px) {
        font-size: 16px;
    }
    @media (max-width: 1024px) {
        padding: 20px 68px;
        font-size: 14px;
    }
    .icon {
        width: 20px;
        height: 20px;
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        @media (max-width: 1024px) {
            width: 16px;
            height: 16px;
            right: 20px;
        }
    }
    &:hover {
        svg {
            path {
                transition: 0.3s;
                fill: #4096ff;
            }
        }
    }
}