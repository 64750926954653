.companyDescr {
    padding: 24px;
    background: #fff;
    border-radius: 12px;
}

.buttons {
    display: flex;
    align-items: center;
    gap: 8px;
}