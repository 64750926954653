.postDescr {
  position: relative;
  height: 100%;
  background: #fff;
  border-radius: 12px;
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__icons {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.picture,
.pictureBlock {
  border-radius: 16px;
  div {
    border-radius: 16px;
  }
}

.postContent,
.postHashtags,
.postLike,
.postActions {
  margin-top: 16px;
}

.postLike {
  display: flex;
  align-items: center;
  gap: 8px;
}

.postActions {
  display: flex;
  gap: 16px;
}

// user-info
.userInfo {
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 16px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.details {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.name {
  height: auto;
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.deleteBtn {
  position: absolute;
  bottom: 24px;
  left: 24px;
  right: 24px;
}
