.canvas-workspace {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;

  .canvas-tools {
    padding: 8px 16px;
    background: #fff;
    border-bottom: 1px solid #f0f0f0;
    z-index: 5;
    display: flex;
    justify-content: flex-end;

    .zoom-controls {
      display: flex;
      align-items: center;
      gap: 8px;

      .zoom-slider {
        width: 120px;
        margin: 0 12px;
      }
    }
  }

  .canvas-container {
    flex: 1;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    
    .konva-stage {
      position: absolute;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
    }
  }
} 