.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.table {
  :global {
    .ant-table-tbody > tr > td {
      background-color: #fff;
    }
    
    .ant-table-tbody > tr:hover > td {
      background-color: #f5f5f5;
    }
  }
}

.tableRow {
  background-color: #fff;
  
  &:hover {
    cursor: pointer;
  }
} 