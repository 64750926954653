.page-layout {
  padding: 24px;
  @media (max-width: 1024px) {
    padding: 16px;
    padding-left: 32px;
  }
}

.main-title {
  margin-bottom: 16px;
  @media (max-width: 1024px) {
    font-size: 36px;
  }
  @media (max-width: 768px) {
    font-size: 32px;
  }
}
