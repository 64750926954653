.itemList {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
}

.item {
    width: calc(25% - 8px);
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin: 8px 0;
    padding: 8px !important;
    border: 1px solid transparent;
    border-radius: 8px;
    transition: 0.3s all;
    cursor: pointer;
    &:hover,
    &__isActive {
        border: 1px solid #1890ff;
        border-block-end: 1px solid #1890ff !important;
        transition: 0.3s all;
    }
}

.expandBtn {
    display: flex;
    justify-content: flex-end;
    button {
        padding: 4px !important;
        height: 24px !important;
        outline: none !important;
    }
}

.username {
    text-align: center;
    margin-bottom: 0 !important;
}

.noContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    &__text {
        font-size: 20px;
        font-weight: 600;
        text-align: center;
    }
    &__link {
        text-align: center;
    }
}