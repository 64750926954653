.container {
  padding: 0;
}

.postQueryDescr {
  padding: 24px;
  background: #fff;
  border-radius: 12px;
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__icons {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.product {
  &__title {
    margin-top: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.iconHeart {
  font-size: 24px;
  &__active {
    path {
      fill: #eb2f96;
    }
  }
}

.titleBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detailsSection {
  padding: 16px;
  margin-bottom: 20px;
}

.detailsGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-top: 16px;
}

.detailItem {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.actionsContainer {
  display: flex;
  justify-content: flex-end;
  padding: 0 16px 16px;
}

.articlesSection {
  padding: 16px;
}

.articleItem {
  background-color: #fff;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.articleTitle {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
}

.articleDate {
  color: #8c8c8c;
  font-size: 12px;
}

.articleText {
  margin-top: 16px;
  white-space: pre-wrap;
}

.postContent__icon {
  margin-left: 8px;
} 