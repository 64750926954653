.menuLayout {
  transition:
    opacity 0.2s ease-in-out,
    visibility 0.2s ease-in-out;
  @media (max-width: 1024px) {
    visibility: hidden;
    opacity: 0;
    background-color: rgba(25, 28, 34, 0.4);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1000;
  }
  &__isOpen {
    visibility: visible;
    opacity: 1;
  }
}

.menuBody {
  position: sticky;
  top: 0;
  height: 100vh;
  width: 300px;
  max-width: 300px;
  background-color: #fff;
  transition: transform 0.3s ease-in-out;
  @media (max-width: 1024px) {
    position: absolute;
    left: 0;
    z-index: 1050;
    transform: translateX(-100%);
  }
  &__isOpen {
    transform: translateX(0);
  }
}

.menuButton {
  position: absolute;
  top: 14px;
  right: -13px;
  padding: 11px 4px;
  z-index: 3;
  background-color: #e6e6e6;
  border-radius: 20px;
  border: 1px solid #bbbbbb;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  svg {
    cursor: pointer;
    @media (max-width: 992px) {
      height: 100%;
      object-fit: cover;
    }
  }
}

.logOutBtn {
  padding: 8px 0;
  position: sticky;
  bottom: 0;
  width: 100%;

  button {
    width: 100%;
  }
}

.soon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
