.section {
    position: relative;
    padding: 0 40px;
    padding-top: 110px;
    @media (max-width: 1263px) {
        padding: 0 30px;
        padding-top: 60px;
    }
    @media (max-width: 1024px) {
        padding: 0 24px;
        padding-top: 40px;
    }
}

.title {
    font-size: 36px;
    font-weight: 500;
    text-align: left;
    @media (max-width: 1263px) {
        font-size: 32px;
    }
    @media (max-width: 1024px) {
        font-size: 28px;
    }
    @media (max-width: 576px) {
        font-size: 20px;
        text-align: center;
    }
}

.works {
    position: relative;
    margin-top: 60px;
    display: flex;
    gap: 12px;
    @media (max-width: 1263px) {
        margin-top: 36px;
    }
    @media (max-width: 1024px) {
        margin-top: 20px;
    }
    &__block {
        width: calc(40% - 8px);
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        @media (max-width: 992px) {
            width: calc(33.333% - 8px);
        }
        &__hand {
            width: calc(20% - 8px);
            @media (max-width: 992px) {
                width: calc(33.333% - 8px);
            }
        }
    }
    &__item {
        width: calc(50% - 6px);
        @media (max-width: 992px) {
            width: 100%;
        }
        img {
            width: 100%;
            height: auto;
            object-fit: cover;
        }
    }
    &__hand {
        position: sticky;
        top: 140px;
        img {
            width: 180%;
            transform: translateX(-15px);
            object-fit: cover;
            @media (max-width: 992px) {
                width: 200%;
                transform: translateX(-32px);
            }
            @media (max-width: 576px) {
                transform: translateX(-22px);
            }
            @media (max-width: 375px) {
                transform: translateX(-14px);
            }
        }
    }
}

.video {
    width: 80%;
    top: 17%;
    left: 50px;
    height: 69%;
    position: absolute;
    z-index: 2;
    object-fit: cover;
    border-radius: 32px;
    width: 82%;
    top: 6px;
    left: calc(12% + 2px);
    @media (max-width: 1440px) {
        width: 81%;
        left: calc(12% + 0px);
    }
    @media (max-width: 1263px) {
        width: 83%;
        top: 3px;
        left: calc(12% + -4px);
    }
    @media (max-width: 1024px) {
        width: 82%;
        top: 2px;
        left: calc(12% + -6px);
    }
    @media (max-width: 992px) {
        width: 90%;
        top: 6px;
        left: calc(12% + -9px);
        border-radius: 40px;
    }
    @media (max-width: 768px) {
        height: auto;
        left: 26px;
        width: 80%;
        top: 20%;
    }
    @media (max-width: 576px) {
        border-radius: 0;
        left: 11px;
        width: 90%;
    }
    @media (max-width: 425px) {
        left: 1px;
        width: 86%;
    }
    @media (max-width: 375px) {
        left: 7px;
    }
}