.postDescr {
  padding: 24px;
  background: #fff;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__icons {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 45px;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
}

.mainBlock {
  width: calc(40% - 30px);
  // max-width: 450px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    width: 100%;
  }
}
.mainContent {
  @media (max-width: 1024px) {
    order: 3;
  }
}

.editBlock {
  width: calc(40% - 30px);
  // max-width: 450px;
}

.picture,
.pictureBlock {
  border-radius: 16px;
  div {
    border-radius: 16px;
  }
}

.postContent {
  &__titleBlock {
    display: flex;
    gap: 12px;
  }
  &__title {
    width: calc(100% - 12px - 32px);
  }
  &__icon {
    min-width: 32px;
    width: 32px;
    height: 32px;
  }
}

.postContent,
.postHashtags,
.postLike,
.postActions {
  margin-top: 16px;
}

.postLike {
  display: flex;
  align-items: center;
  gap: 8px;
}

.postActions {
  display: flex;
  gap: 16px;
  margin-top: 40px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
}

.pictureBlock {
  position: relative;
}

.reloadButton,
.downloadButton,
.editInDesignerButton,
.refreshImageButton {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
}

.downloadButton {
  right: calc(32px + 16px);
}

.editInDesignerButton {
  right: calc(32px + 16px + 32px + 16px);
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.8);
  
  &.hasTemplate {
    background-color: rgba(24, 144, 255, 0.2);
    border-color: #1890ff;
    color: #1890ff;
    
    &:hover {
      background-color: rgba(24, 144, 255, 0.3);
      border-color: #40a9ff;
      color: #40a9ff;
    }
  }
}

.refreshImageButton {
  right: calc(32px + 16px + 32px + 16px + 32px + 16px);
}

.imageRefreshNotice {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 12px;
  z-index: 1;
  max-width: 80%;
  text-align: center;
}

.viewDirectLink {
  color: #1890ff;
  text-decoration: underline;
  &:hover {
    color: #40a9ff;
  }
}

.parameters {
  margin-top: 50px;
}

.radioGroup {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.textArea {
  margin: 12px 0;
}

.submitButton {
  margin-top: 12px;
}

.loader {
  text-align: center;
  display: flex;
  justify-content: center;
  font-size: 100px;
  color: rgb(22, 119, 255);
}

.imageStyleBlock {
  cursor: pointer;
  img {
    width: 160px;
    height: auto;
    border-radius: 16px;
  }
}

// user-info
.userInfo {
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 16px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.details {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.name {
  height: auto;
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.iconHeart {
  font-size: 24px;
  cursor: pointer;
  path {
    transition: 0.3s all;
  }
  &__active,
  &:hover {
    transition: 0.3s all;
    path {
      transition: 0.3s all;
      fill: #eb2f96;
    }
  }
}

.mediaSlider {
  width: calc(20% - 30px);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1024px) {
    width: 100%;
    order: 2;
  }
}

.scrollContainer {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scroll-behavior: smooth;
  gap: 12px;
  // margin-top: 48px;
  // height: calc(100vh - 48px - 48px - 70px - 140px);
  height: 70vh;
  @media (max-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: auto;
    flex-direction: row;
    height: 39vh;
  }
}
.postButtons {
  @media (max-width: 1024px) {
    order: 3;
  }
}

.scrollContainer::-webkit-scrollbar {
  display: none;
}

.imageWrapper.selected {
  border-color: #1890ff;
}

.sliderImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.3s;
}

.sliderImage:hover {
  transform: scale(1.05);
}

.scrollButton {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  // position: absolute;
  // z-index: 1;
  // left: 50%;
  // transform: translateX(-50%);

  width: 32px;
  height: 32px;

  &:first-child {
    top: 4px;
    margin-bottom: 12px;
  }

  &Down {
    margin-top: 12px;
  }
}

.scrollButton:hover {
  background: rgba(0, 0, 0, 0.8);
}

.upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;

  // position: absolute;
  // z-index: 1;
  // bottom: 0;
}
.uploadPicture {
  margin-top: 10px;
}

.uploadBanner {
  margin-top: 10px;
  margin-bottom: 10px;
}

.uploadConfirm {
  margin-top: 10px;
}

.imageWrapper {
  position: relative;
  flex: 0 0 auto;
  width: 160px;
  height: 160px;
  cursor: pointer;
  border: 2px solid transparent;
  transition: border-color 0.3s;
}

.imageWrapper:hover {
  border-color: rgba(0, 0, 0, 0.5);
}

.iconOverlay {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: cell;
  transition: all 0.3s;

  &__download {
    min-width: auto !important;
    width: 24px !important;
    right: 36px;
    cursor: pointer;
  }
  
  &__edit {
    min-width: auto !important;
    width: 24px !important;
    right: 64px;
    cursor: pointer;
    
    &.hasTemplate {
      background-color: rgba(24, 144, 255, 0.8);
      
      &:hover {
        background-color: rgba(24, 144, 255, 1);
      }
    }
  }
}

.iconOverlay:hover {
  background: rgba(0, 0, 0, 0.8);
}

.iconOverlay.selected {
  background: #1890ff;
}

.iconOverlay__delete {
  top: 8px;
  right: 90px;
  background-color: #ff4d4f;
  color: white;
  border-color: #ff4d4f;
  
  &:hover, &:focus {
    background-color: #ff7875;
    color: white;
    border-color: #ff7875;
  }
}