.template-list-page {
  padding: 24px;

  .template-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    flex-wrap: wrap;
    gap: 16px;

    h2 {
      margin-bottom: 0;
    }

    .template-actions {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 8px;
    }
  }

  .template-tabs {
    margin-bottom: 24px;
  }

  .templates-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
  }

  .templates-grid {
    margin-top: 16px;
  }

  .template-card {
    transition: all 0.3s ease;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);

    &:hover {
      transform: translateY(-4px);
      box-shadow: 0 6px 16px rgba(0, 0, 0, 0.12);
    }

    .heart-icon {
      font-size: 18px;
      
      &.filled {
        color: #ff4d4f;
      }
    }

    .template-preview {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 16px;
      background-color: #f5f5f5;
      border-radius: 4px;
      overflow: hidden;
      
      .preview-container {
        position: relative;
        width: 100%;
        height: 0;
        background-color: white;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        
        &.square {
          padding-bottom: 100%; // 1:1 aspect ratio
        }
        
        &.portrait {
          padding-bottom: 177.78%; // 9:16 aspect ratio
        }
        
        .template-content-preview {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          overflow: hidden;
          
          img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
          }
          
          .preview-shape, .preview-text {
            position: absolute;
          }
        }
      }
    }

    .template-info {
      padding: 12px;
      border-top: 1px solid #f0f0f0;
      background-color: #fff;

      .template-name {
        font-weight: 500;
        margin-bottom: 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .template-size {
        font-size: 12px;
        color: #999;
        
        .template-default-badge {
          display: inline-block;
          color: #1890ff;
          font-weight: 500;
        }
      }
    }
  }

  .no-templates {
    text-align: center;
    padding: 48px 0;
    
    p {
      margin-bottom: 16px;
      color: #666;
    }
  }
}

/* Force all images to be visible with higher specificity */
.template-list-page .template-preview .preview-container img,
.template-list-page .template-preview img.preview-image,
img.preview-image {
  display: block !important;
  opacity: 1 !important;
  visibility: visible !important;
  width: 100% !important;
  height: 100% !important;
  object-fit: contain !important;
}

/* Ensure shapes and text are visible with even higher specificity */
.template-list-page .template-preview .preview-container .preview-shape,
.template-list-page .template-preview .preview-container .preview-text,
.template-content-preview .preview-shape,
.template-content-preview .preview-text {
  display: block !important;
  opacity: 1 !important;
  visibility: visible !important;
}

/* Preview text style */
.template-content-preview .preview-text {
  text-align: center;
  min-width: 50px;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}

/* Special heading text style */
.template-content-preview div.preview-text {
  font-size: 18px !important;
  font-weight: bold !important;
  color: black !important;
  line-height: 1.2 !important;
}

.template-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 4px;
}

.template-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #999;
  font-size: 14px;
  text-align: center;
  padding: 16px;
  
  .template-size-indicator {
    font-size: 12px;
    margin-top: 4px;
    color: #bbb;
  }
}

.templates-section-header {
  margin: 24px 0 16px;
  
  h4 {
    margin-bottom: 0;
    font-weight: 500;
  }
}

.assignable-tag {
  margin-left: 8px;
  font-size: 11px;
  line-height: 16px;
  padding: 0 5px;
  display: inline-flex;
  align-items: center;
  
  .anticon {
    margin-right: 3px;
    font-size: 10px;
  }
} 