.content {
  display: flex;
}

.container {
  padding: 24px;
  background: #fff;
  border-radius: 12px;
  width: 70%;
  transition: width 0.3s ease;
  &IsFull {
    width: 100%;
    transition: width 0.3s ease;
  }
}

.calendar {
  margin-right: 16px;
  width: calc(70% - 16px);
  padding: 24px;
  background: #fff;
  border-radius: 12px;
  @media (max-width: 1263px) {
    width: calc(65% - 16px);
  }
  @media (max-width: 1024px) {
    margin-right: 0;
    width: 100%;
  }
  &IsFull {
    width: 100%;
  }
}

.selectedEvents {
  padding: 16px;
  border-radius: 16px;
  background-color: #fff;
}

.selectedPost {
  margin: 8px 0;
  padding: 8px !important;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 8px;
  transition: 0.3s all;
  &:hover,
  &__isActive {
    border: 1px solid #1890ff;
    border-block-end: 1px solid #1890ff !important;
    transition: 0.3s all;
  }
  &__content {
    align-items: center !important;
  }
  &__text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
  }
  &__title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  &__time {
    color: rgba(0, 0, 0, 0.5);
  }
  h4 {
    margin-bottom: 0 !important;
  }
}

.previewBlock {
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (max-width: 1263px) {
    width: 35%;
  }
  @media (max-width: 1024px) {
    display: none;
  }
}

.icon {
  width: 32px;
  height: 32px;
}
