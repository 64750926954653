.loginBox {
    width: 350px;
    padding: 20px;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.loginBox h2 {
    margin-bottom: 20px;
    color: #333333;
}

.loginForm {
    width: 100%;
}

.loginFormButton {
    width: 100%;
    border-radius: 20px;
    height: 40px;
    border: none;
}

.loginFormForgot,
.loginForm a {
    display: block;
    text-align: right;
    color: #1890ff;
    margin-top: 10px;
}

.loginFormForgot:hover,
.loginForm a:hover {
    color: #40a9ff;
}

.linksBlock {
    display: flex;
    justify-content: space-between;
}