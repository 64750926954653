.container {
    margin-top: 12px;
    display: flex;
    gap: 16px;
}

.calendar {
    width: 100%;
}

.eventContainer {
    background-color: transparent !important;
    padding: 0 5px;
}

.eventTitle {
    font-weight: bold;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}