.section {
    border-radius: 40px;
    width: 100%;
    height: 100vh;
    max-height: 860px;
    position: relative;
    overflow: hidden;
    @media (max-width: 1263px) {
        max-height: 720px;
    }
    @media (max-width: 1024px) {
        max-height: 560px;
    }
}

.video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    border-radius: 40px;
}