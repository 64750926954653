.header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    height: 124px !important;
    padding: 30px 40px;
    height: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(238, 238, 238, 1);
    @media (max-width: 1263px) {
        height: 103px !important;
        padding: 20px 30px;
    }
    @media (max-width: 1024px) {
        padding: 12px 24px;
    }
    @media (max-width: 576px) {
        padding: 20px;
    }
}

.logo {
    position: fixed;
    top: 30px;
    left: 40px;
    @media (max-width: 1263px) {
        top: 20px;
        left: 30px;
    }
    @media (max-width: 1100px) {
        position: static;
        top: 0;
        left: 0;
    }
    @media (max-width: 992px) {
        height: 50px;
    }
    svg {
        cursor: pointer;
        @media (max-width: 992px) {
            height: 100%;
            object-fit: cover;
        }
    }
}

.actions {
    display: flex;
    align-items: center;
    gap: 10px;
    position: fixed;
    right: 40px;
    top: 32px;
    @media (max-width: 1263px) {
        top: 28px;
        right: 30px;
    }
    @media (max-width: 1100px) {
        position: static;
        top: 0;
        right: 0;
    }
}

.languageButton {
    height: auto;
    padding: 14px 40px;
    background-color: transparent;
    border-color: rgba(27, 27, 27, 1);
    border-radius: 100px;
    color: var(--black, #1B1B1B);
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    @media (max-width: 1263px) {
        padding: 12px 30px;
    }
    @media (max-width: 1024px) {
        padding: 8px 16px;
    }
    @media (max-width: 576px) {
        padding: 8px 20px;
    }
}

.startButton {
    height: auto;
    padding: 14px 40px;
    background-color: rgba(27, 27, 27, 1);
    border-color: rgba(27, 27, 27, 1);
    border-radius: 100px;
    color: var(--white, #FFF);
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    @media (max-width: 1263px) {
        padding: 12px 30px;
    }
    @media (max-width: 1024px) {
        padding: 8px 16px;
    }
    @media (max-width: 576px) {
        padding: 8px 20px;
    }
}