.elements-panel {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 0;

  .elements-tabs {
    height: 100%;
    
    .ant-tabs-content {
      height: 100%;
      
      .ant-tabs-tabpane {
        height: 100%;
      }
    }

    .tab-content {
      height: 100%;
      overflow-y: auto;
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .image-uploader {
        flex-shrink: 0;
        margin-bottom: 16px;
      }

      .my-assets-section {
        flex-grow: 1;
        overflow-y: auto;
        
        .section-title {
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 16px;
          color: #1890ff;
        }

        .asset-card {
          margin-bottom: 16px;
          
          .asset-image-container {
            height: 100px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            
            .asset-image {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          
          .asset-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 12px;
          }
        }

        .refresh-assets {
          text-align: center;
          margin-top: 8px;
        }
      }
    }
  }

  /* Elements List Panel */
  .elements-list-panel {
    margin-top: 16px;
    border-top: 1px solid #f0f0f0;
    padding-top: 16px;

    .elements-list-header {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      font-weight: 500;
      color: #333;

      .anticon {
        margin-right: 8px;
      }
    }

    .elements-list {
      .element-list-item {
        display: flex;
        align-items: center;
        padding: 8px 12px;
        border-radius: 4px;
        margin-bottom: 4px;
        cursor: pointer;
        transition: all 0.2s;

        &:hover {
          background: #f5f5f5;
        }

        &.selected {
          background: #e6f7ff;
          border: 1px solid #91d5ff;
        }

        .element-list-icon {
          margin-right: 8px;
          color: #666;
        }

        .element-list-name {
          flex: 1;
          color: #333;
        }

        .element-list-z-index {
          margin: 0 8px;
          color: #999;
          font-size: 12px;
        }

        .element-list-actions {
          display: flex;
          gap: 4px;
          opacity: 0;
          transition: opacity 0.2s;

          .ant-btn {
            padding: 0 4px;
            height: 24px;
            min-width: 24px;

            &:hover {
              color: #1890ff;
              background: rgba(24, 144, 255, 0.1);
            }
          }
        }

        &:hover .element-list-actions {
          opacity: 1;
        }
      }
    }
  }

  /* User Assets */
  .assets-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 150px;
    width: 100%;
  }

  .asset-card {
    margin-bottom: 12px;
    
    .asset-image-container {
      width: 100%;
      height: 80px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f0f0f0;
    }
    
    .asset-image {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    
    .asset-name {
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 4px;
    }
  }
  
  .refresh-assets {
    display: flex;
    justify-content: center;
    margin-top: 12px;
  }

  .element-card {
    margin-bottom: 8px;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    }
  }

  .shape-card {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    background: #f0f2f5;
    border-radius: 4px;
    margin-bottom: 8px;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background: #e6e9ed;
    }

    .rectangle {
      width: 60px;
      height: 40px;
      background: #1890ff;
    }

    .circle {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: #1890ff;
    }

    .triangle {
      width: 0;
      height: 0;
      border-left: 30px solid transparent;
      border-right: 30px solid transparent;
      border-bottom: 50px solid #1890ff;
    }

    .line {
      width: 80px;
      height: 2px;
      background: #1890ff;
    }

    .star {
      position: relative;
      width: 0;
      height: 0;
      border-right: 25px solid transparent;
      border-bottom: 18px solid #1890ff;
      border-left: 25px solid transparent;
      transform: rotate(35deg);

      &:before {
        content: '';
        position: absolute;
        border-bottom: 20px solid #1890ff;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        top: -11px;
        left: -16px;
        transform: rotate(-35deg);
      }

      &:after {
        content: '';
        position: absolute;
        border-bottom: 20px solid #1890ff;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        top: -11px;
        left: -16px;
        transform: rotate(-70deg);
      }
    }
  }

  .shape-preview {
    width: 40px;
    height: 40px;
    margin: 0 auto 8px;
    
    &.shape-rectangle {
      background-color: #4A90E2;
    }
    
    &.shape-circle {
      background-color: #7ED321;
      border-radius: 50%;
    }
    
    &.shape-triangle {
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 40px solid #F5A623;
      background-color: transparent;
    }
    
    &.shape-line {
      height: 4px;
      background-color: #9013FE;
      margin-top: 18px;
    }
    
    &.shape-star {
      position: relative;
      display: block;
      width: 0;
      height: 0;
      margin: 0 auto;
      border-right: 20px solid transparent;
      border-bottom: 14px solid #F8E71C;
      border-left: 20px solid transparent;
      transform: rotate(35deg);
      
      &:before {
        content: '';
        position: absolute;
        height: 0;
        width: 0;
        top: -9px;
        left: -13px;
        border-right: 8px solid transparent;
        border-bottom: 25px solid #F8E71C;
        border-left: 8px solid transparent;
        transform: rotate(-35deg);
      }
      
      &:after {
        content: '';
        position: absolute;
        top: 3px;
        left: -21px;
        height: 0;
        width: 0;
        border-right: 20px solid transparent;
        border-bottom: 14px solid #F8E71C;
        border-left: 20px solid transparent;
        transform: rotate(-70deg);
      }
    }
  }

  .shape-name {
    font-size: 12px;
    margin-top: 4px;
  }

  .image-uploader {
    .ant-upload {
      width: 100%;
      height: 190px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #fafafa;
      border: 1px dashed #d9d9d9;
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        border-color: #1890ff;
      }

      .anticon {
        font-size: 24px;
        color: #999;
        margin-bottom: 8px;
      }

      .upload-text {
        color: #666;
      }
    }
  }

  // Templates Tab Styles
  .template-search {
    margin-bottom: 16px;
    
    .template-search-input {
      width: 100%;
      border-radius: 4px;
    }
  }
  
  .template-filter {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    
    .ant-radio-group {
      width: 100%;
      display: flex;
      
      .ant-radio-button-wrapper {
        flex: 1;
        text-align: center;
      }
    }

    .template-size-info {
      margin-top: 8px;
      font-size: 12px;
      color: #666;
      text-align: center;
    }
  }
  
  .templates-container {
    flex-grow: 1;
    overflow-y: auto;
    
    .templates-loading {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 150px;
    }
    
    .template-card {
      margin-bottom: 16px;
      
      .template-preview {
        height: 120px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f5f5f5;
        
        .template-preview-container {
          position: relative;
          width: 100%;
          height: 100%;
          overflow: hidden;

          .template-background {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .template-element {
            position: absolute;
            transform-origin: center;
            
            &.text-element {
              display: flex;
              align-items: center;
              justify-content: center;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              max-width: 50px;
              font-weight: bold;
            }
            
            &.image-element {
              overflow: hidden;
            }
            
            &.shape-element {
              &.shape-rectangle {
                border-radius: 0;
              }
              
              &.shape-circle {
                border-radius: 50%;
              }
              
              &.shape-triangle {
                clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
              }
              
              &.shape-star {
                clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
              }
              
              &.shape-line {
                height: 2px !important;
                transform: translateY(-50%);
              }
            }
          }
        }
        
        .template-default-icon {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          background-color: #f7f7f7;
          color: #999;
          
          .template-icon-grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 12px;
            margin-bottom: 12px;
            
            .grid-item {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 32px;
              height: 32px;
              background-color: #fff;
              border-radius: 6px;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
              
              .anticon {
                font-size: 18px;
                color: #666;
              }
              
              &:hover {
                background-color: #f0f0f0;
                transform: translateY(-1px);
                transition: all 0.2s ease;
              }
            }
          }
          
          .template-size {
            font-size: 12px;
            color: #666;
            background-color: #fff;
            padding: 4px 8px;
            border-radius: 4px;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
          }
        }
      }
      
      .template-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
      }
    }
  }
} 