.section {
    padding: 180px 40px;
    @media (max-width: 1263px) {
        padding: 80px 30px;
    }
    @media (max-width: 1024px) {
        padding: 56px 24px;
    }
    br {
        @media (max-width: 1024px) {
            display: none;
        }
    }
}

.title {
    margin-bottom: 20px;
    color: var(--black, #1B1B1B);
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    @media (max-width: 1263px) {
        font-size: 32px;
        line-height: 32px;
    }
    @media (max-width: 1024px) {
        font-size: 28px;
    }
}

.advantagesBlock {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.advantagesRow {
    display: flex;
    gap: 10px;
    @media (max-width: 992px) {
        flex-wrap: wrap;
    }
    &__end {
        justify-content: flex-end;
    }
}

.advantageItem {
    width: calc(37% - 5px);
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--black, #1B1B1B);
    border-radius: 140px;
    transition: 0.3s;
    cursor: pointer;
    &:hover {
        background-color: white;
        border: 1px solid white;
        transition: 0.3s;
    }
    @media (max-width: 1024px) {
        padding: 16px;
    }
    @media (max-width: 992px) {
        width: 100%;
    }
    &__big {
        width: calc(63% - 5px);
        @media (max-width: 992px) {
            width: 100%;
        }
    }
    &__reverse {
        @media (max-width: 992px) {
            flex-direction: row-reverse;
        }
    }
    &__title {
        color: var(--black, #1B1B1B);
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        @media (max-width: 1263px) {
            font-size: 18px;
        }
        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }
    &__icon {
        width: 50px;
        height: 50px;
        @media (max-width: 1263px) {
            width: 32px;
            height: 32px;
        }
        @media (max-width: 1024px) {
            width: 24px;
            height: 24px;
        }
    }
}

.icon {
    font-size: 24px;
    margin-right: 15px;
    color: #007bff;
}

.text {
    font-size: 18px;
    font-weight: 500;
    color: #333;
}