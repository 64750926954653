.tariffSlider {
  width: 100%;
  height: 90px !important;
  position: relative;
  @media (max-width: 1263px) {
    height: 60px !important;
  }
  @media (max-width: 1024px) {
    height: 40px !important;
  }
  .ant-slider-track {
    background: linear-gradient(90deg, #fff 25.6%, #005faa 100%);
    border-radius: 20px !important;
    height: 90px !important;
    @media (max-width: 1263px) {
      height: 60px !important;
    }
    @media (max-width: 1024px) {
      height: 40px !important;
    }
    @media (max-width: 992px) {
      height: 90px !important;
    }
  }
  .ant-slider-rail {
    background: #2a2a2a !important;
    border-radius: 20px !important;
    height: 90px !important;
    @media (max-width: 1263px) {
      height: 60px !important;
    }
    @media (max-width: 1024px) {
      height: 40px !important;
    }
    @media (max-width: 992px) {
      height: 90px !important;
    }
  }
  .ant-slider-handle {
    inset-block-start: 45px !important;
    @media (max-width: 1263px) {
      inset-block-start: 30px !important;
    }
    @media (max-width: 1024px) {
      inset-block-start: 20px !important;
    }
    @media (max-width: 992px) {
      inset-block-start: 45px !important;
    }
    /* width: 4px !important;
        height: 4px !important;
        background-color: #fff !important;
        border: none !important;
        border-radius: 0 !important; */
  }
  /* .ant-slider-handle::before {
        inset-inline-start: -14px !important;
        inset-block-start: -8px !important;
        width: 4px !important;
        height: 4px !important;
        background-color: #fff !important;
        border: none !important;
        border-radius: 0 !important;
    }

    .ant-slider-handle::after {
        inset-inline-start: -14px !important;
        inset-block-start: 8px !important;
        width: 4px !important;
        height: 4px !important;
        background-color: #fff !important;
        border: none !important;
        border-radius: 0 !important;
        box-shadow: none !important;
    } */
  .ant-slider-mark-text {
    margin-top: 100px !important;
    color: var(--grey, #7e8287) !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    @media (max-width: 1263px) {
      margin-top: 60px !important;
    }
    @media (max-width: 1024px) {
      margin-top: 40px !important;
      font-size: 12px !important;
    }
    @media (max-width: 992px) {
      margin-top: 100px !important;
    }
  }
  .ant-slider-dot {
    background-color: transparent !important;
    border-color: transparent !important;
  }
}

.tariff-page-slider {
  height: 40px !important;
  .ant-slider-track {
    height: 40px !important;
  }
  .ant-slider-rail {
    height: 40px !important;
  }
  .ant-slider-handle {
    inset-block-start: 20px !important;
  }
  .ant-slider-mark-text {
    margin-top: 40px !important;
  }
}
