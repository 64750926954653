.item {
    margin: 8px 0;
    padding: 8px !important;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 8px;
    transition: 0.3s all;
    &:hover,
    &__isActive {
        border: 1px solid #1890ff;
        border-block-end: 1px solid #1890ff !important;
        transition: 0.3s all;
    }
}

.expandBtn {
    display: flex;
    justify-content: flex-end;
    button {
        padding: 4px !important;
        height: 24px !important;
        outline: none !important;
    }
}

.modalWithScroll {
    height: 650px !important;
    overflow-y: auto !important;
}

.tab {
    border: 2px solid red;
}