.section {
    margin: 140px 80px;
    display: flex;
    flex-direction: column;
    gap: 48px;
    @media (max-width: 1263px) {
        margin: 100px 30px;
        gap: 32px;
    }
    @media (max-width: 1024px) {
        margin: 64px 24px;
        gap: 24px;
    }
    @media (max-width: 576px) {
        gap: 30px;
    }
}

.title {
    font-size: 36px;
    font-weight: 500;
    text-align: center;
    color: rgba(27, 27, 27, 1);
    @media (max-width: 1024px) {
        font-size: 32px;
    }
    @media (max-width: 576px) {
        font-size: 20px;
    }
}

.socialMedia {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 100px;
    @media (max-width: 1263px) {
        gap: 72px;
    }
    @media (max-width: 992px) {
        width: 100%;
        overflow-x: auto;
        white-space: nowrap;
        gap: 0;
    }
    &__item {
        width: calc(20% - 80px);
        @media (max-width: 1263px) {
            width: calc(20% - 57.6px);
        }
        @media (max-width: 992px) {
            width: 40%;
            display: inline-flex;
            padding-left: 50px;
            &:first-child {
                padding-left: 0;
            }
        }
        @media (max-width: 576px) {
            width: 100%;
        }
        img {
            width: 100%;
            height: auto;
            object-fit: cover;
            @media (max-width: 992px) {
                width: auto;
            }
        }
    }
}