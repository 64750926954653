.title {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.addBtn {
  border-radius: 999px;
  background: var(--Dark, #1b1b1b);
  border: 1px solid #1b1b1b;
  color: var(--White, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.icon {
  width: 32px;
  height: 32px;
}
