.addBtns {
  display: flex;
  align-items: center;
  gap: 8px;
}

.addBtn {
  &__isActive {
    color: #4096ff;
    border-color: #4096ff;
    background: #ffffff;
  }
}

.selectNewPost {
  display: flex;
  gap: 16px;
}

.selectNewPost {
  margin: 8px 0;
  padding: 8px !important;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 8px;
  transition: 0.3s all;
  &:hover,
  &__isActive {
    border: 1px solid #1890ff;
    border-block-end: 1px solid #1890ff !important;
    transition: 0.3s all;
  }
}

.expandBtn {
  display: flex;
  justify-content: flex-end;
  button {
    padding: 4px !important;
    height: 24px !important;
    outline: none !important;
  }
}

.selectNewSocialMedia {
  width: calc(25% - 8px);
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 8px 0;
  padding: 8px !important;
  border: 1px solid transparent;
  border-radius: 8px;
  transition: 0.3s all;
  cursor: pointer;
  &:hover,
  &__isActive {
    border: 1px solid #1890ff;
    border-block-end: 1px solid #1890ff !important;
    transition: 0.3s all;
  }
}

.itemList {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

.username {
  text-align: center;
  margin-bottom: 0 !important;
}

.dateTimeBlock {
  margin-top: 8px;
  display: flex;
  gap: 8px;
}

.datePicker,
.timePicker {
  width: 50%;
}

.socialMediaAddBtn {
  margin-bottom: 8px;
}
