.messageButton {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 1000;
  width: 64px !important;
  height: 64px !important;
  font-size: 24px;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 1263px) {
    width: 56px !important;
    height: 56px !important;
  }

  @media (max-width: 768px) {
    bottom: 20px;
    right: 20px;
  }

  .iconMessage {
    font-size: 40px !important;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(31, 115, 183, 0.7);
  }
  70% {
    transform: scale(1.1);
    box-shadow: 0 0 0 10px rgba(31, 115, 183, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(31, 115, 183, 0);
  }
}

.messageButton {
  animation: pulse 2s infinite;
}
